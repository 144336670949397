import Component from 'inferno-component'
import 'whatwg-fetch'

class UserStats extends Component<any,any> {

  constructor(props) {
    super()

    this.state = {
      stats: props.stats,
      fromdate: this.formatDateOffset(-3),
      todate: this.formatDateOffset(0),
      isloading: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFromDateChange = this.handleFromDateChange.bind(this)
    this.handleToDateChange = this.handleToDateChange.bind(this)
  }

  componentDidMount() {
    this.requestData()
  }

  handleFromDateChange(e) {
    this.setState({ fromdate: e.target.value })
  }

  handleToDateChange(e) {
    this.setState({ todate: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.requestData()
  }

  requestData() {
    this.setState({ isloading: true })

    const { fromdate, todate } = this.state

    let formData = new FormData()

    formData.append('sesskey', M.cfg.sesskey)
    formData.append('fromdate', fromdate)
    formData.append('todate', todate)

    fetch('/report/userstats/ajax.php?sesskey='+M.cfg.sesskey, {
      method: 'POST', mode: 'cors', credentials: 'same-origin',
      body: formData
    })
    .then(response => response.json())
    .then(json => {
      this.setState({
        isloading: false,
        stats: json
      })
    })
  }

  formatDateOffset(offset = 0) {
    const aDay = 24*60*60*1000
    const offsetTime = Date.now() + offset * aDay;
    const offsetDate = new Date(offsetTime)

    let year:any = offsetDate.getFullYear()
    let month:any = offsetDate.getMonth() + 1
    month = month < 10 ? `0${month}` : month
    let day:any = offsetDate.getDate()
    day = day < 10 ? `0${day}` : day

    return `${year}-${month}-${day}`
  }

  render() {

    const maxValue = Math.max(...this.state.stats)
    const today = this.formatDateOffset()

    return (
      <div class="userstats-table">
        <form action="/report/userstats/ajax.php" method="post" onSubmit={this.handleSubmit}>
          <label for="fromdate">Von</label>
          <input id="fromdate" name="fromdate" type="date" value={this.state.fromdate} max={today} onInput={this.handleFromDateChange} />
          <label for="todate">Bis</label>
          <input id="todate" name="todate" type="date" value={this.state.todate} max={today} onInput={this.handleToDateChange}/>
          {
            this.state.isloading ?
              <input disabled value="Lade..." type="submit" /> :
              <input value="Anfordern" type="submit" />
          }
        </form>
        <div class="userstats-table__bars">
          {
            this.state.stats.map((count, i) => (
              <div class="userstats-table__bar-frame" title={`${count} Aufrufe`}>
                <div class="userstats-table__bar" style={`height: ${Math.round(count / maxValue * 100)}%`} title={`${count} Aufrufe`}></div>
                <div class="userstats-table__label">{i}</div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}

export default UserStats
