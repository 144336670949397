import Inferno from 'inferno'
import Component from 'inferno-component'
import classNames from 'classnames'
import 'whatwg-fetch'

class LearningSphereList extends Component<any, any> {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      learningspheres: []
    }

    this.clickHandler = this.clickHandler.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {

    this.setState({
      isLoading: true
    })

    fetch('/local/lernprofil/ajax.php?action=fetch_learningspheres&sesskey='+M.cfg.sesskey, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin'
    })
    .then(response => {
      return response.json()
    }).then(json => {
      if(json && json.learningspheres) {
        this.setState({
          learningspheres: json.learningspheres
        })
      }
    })
  }

  clickHandler(e, id) {
    e.preventDefault()

    fetch('/local/lernprofil/ajax.php?action=set_learningspheres&id='+id+'&sesskey='+M.cfg.sesskey, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin'
    })
    .then(response => {
      return response.json()
    }).then(json => {
      if(json && json.learningspheres) {
        this.setState({
          learningspheres: json.learningspheres
        })
        location.reload()
      }
    })
  }

  render() {
    return (
      <div class="learning-sphere-picker__list-wrapper">
        <a class="learning-sphere-picker__catalog-link" href="/local/cm/course_catalog.php">Lernraumkatalog</a>
        <ul class="learning-sphere-picker__list">
          {
            !this.state.learningspheres.length ? null :
              this.state.learningspheres.map(item => (
                item.IsCurrent ? 
                  <li><a class="learning-sphere-picker__list-item learning-sphere-picker__list-item--current">{item.Title}</a></li> :
                  <li><a onClick={e => this.clickHandler(e, item.ID)} class="learning-sphere-picker__list-item">{item.Title}</a></li>
            ))
          }
        </ul>
      </div>
    )
  }
}

export default LearningSphereList
