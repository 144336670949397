import UserStats from '@components/userstats'

import { render } from 'inferno'
import { on, off, fire } from '../misc/delegated-events-custom'

function bootstrapMisc() {
  bootstrapLearningSphereLinks()
  bootstrapUserStats()
}

const bootstrapLearningSphereLinks = () => {
  on('click', '[data-action="show-learning-sphere"]', e => {
    e.preventDefault()
    const linkElem = e.target
    const courseId = linkElem.getAttribute('data-id')
    const url = `/local/lernprofil/ajax.php?action=set_learningspheres&id=${courseId}`
    const successCb = () => {
      window.location.href = '/'
    }
    return clickHandlerAjax(url, successCb)
  })
}

const clickHandlerAjax = (url, successCb) => {

  fetch(`${url}&sesskey=${M.cfg.sesskey}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin'
  })
  .then(response => response.json())
  .then(json => {
    console.log(json)
    if(successCb) { successCb() }
  })
}

const bootstrapUserStats = () => {
  const userstatsContainer = document.getElementById('report-userstats-accesstable')
  if(!userstatsContainer) { return }

  const props = {
    stats: [ 0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0 ]
  }

	render(
    <UserStats {...props} />,
		userstatsContainer
	)
}

export default bootstrapMisc
