import { createCookie } from '@misc/utilities'

const bootstrapCookieConsent = () => {
  const cookieconsentMessage = document.getElementById('cookieconsent')

  if(cookieconsentMessage) {
    const cookieconsentButton = document.getElementById('cookieconsent_dismiss')

    cookieconsentButton.addEventListener('click', e => {
      e.preventDefault()

      createCookie('cookieconsent_status', 'dismiss', 365)
      cookieconsentMessage.classList.add('cookieconsent-dismissed')
    })
  }
}

export default bootstrapCookieConsent
