import Inferno from 'inferno'
import Component from 'inferno-component'
import classNames from 'classnames'

import { animationEvent } from '@misc/utilities'

const keyCodes = {
  KEY_ARROW_LEFT: 37,
  KEY_ARROW_RIGHT: 39,
  KEY_ESC: 27,
}

class Modal extends Component<any, any> {

  constructor(props) {
    super(props)

    this.state = {
      isFading: false,
      padding: 20
    }

    this.scrollbarSet()

    if(this.props.options.beforeOpen)
      this.props.options.beforeOpen()

    // events
    this.handleClickClose = this.handleClickClose.bind(this)
    this.handleClickBackground = this.handleClickBackground.bind(this)
    this.handleKeyup = this.handleKeyup.bind(this)
  }

  componentDidMount() {
    if(this.props.options.onOpen)
      this.props.options.onOpen(this.wrapperElement)

    this.registerKeys()
  }

  componentWillUnmount() {
    this.unregisterKeys()
  }

  handleClickClose(e) {
    e.preventDefault()
    e.stopPropagation()

    this.closeModal()
  }

  handleClickBackground(e) {
    if(!e.target.closest('.modal'))
      this.closeModal()
  }

  closeModal() {
    this.setState({ isFading: true })

    if(this.props.options.beforeClose)
      this.props.options.beforeClose(this.wrapperElement);

    return animationEvent ?
      this.wrapperElement.addEventListener(animationEvent, e => this._closeModal()) :
      this._closeModal()
  }

  _closeModal() {

    if(this.props.options.onClose)
      this.props.options.onClose()

    this.scrollbarUnset()
    this.props.destroy()
  }

  renderTitle() {

    const { title = '[Title]', options = {} } = this.props

    if(options.notitle)
      return null

    return (
      <div class="modal-title">{ title }</div>
    )
  }

  render() {

    const {
      options =  {},
    } = this.props

    const { width, height, type } = options

    let modalStyles = {
    }

    let modalContentStyles = {
      height: height || null,
    }

    const classes = classNames(
      'animate',
      {
        'fading': this.state.isFading,
        [`modal-${type}`]: type
      }
    )

    const wrapperProps = {
      ref: node => this.wrapperElement = node,
      className: `modal-wrapper ${classes}`,
      onClick: this.handleClickBackground
    }

    return (
      <div {...wrapperProps}>
        <div class="modal" style={modalStyles}>
          <div class="close-button" onClick={this.handleClickClose}></div>
          <div class="modal-inner">
            { this.renderTitle() }
            <div class="modal-content" style={modalContentStyles}>
              { this.props.children }
            </div>
          </div>
        </div>
      </div>
    )
  }

  scrollbarSet() {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    document.body.classList.add('modal-open')
    document.body.style.paddingRight = scrollbarWidth + 'px'
  }

  scrollbarUnset() {
    document.body.classList.remove('modal-open')
    document.body.style.paddingRight = '0'
  }

  registerKeys() {
    document.addEventListener('keyup', this.handleKeyup)
  }

  unregisterKeys() {
    document.removeEventListener('keyup', this.handleKeyup)
  }

  handleKeyup(e) {
    e.preventDefault()
    e.stopPropagation()

    switch(e.keyCode) {
      case keyCodes.KEY_ESC: { return this.closeModal() }
    }
  }
}

export default Modal
