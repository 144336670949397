import { render } from 'inferno'
import extend from 'xtend'
import Modal from '@components/modal'
import LearningSphereList from '@components/learning-sphere-list'

import {
	forEach, getElementByHashLink, sanitizeElementData
} from '@misc/utilities'

import { infernoRemoveRootNode } from '@misc/utilities-inferno'

const allowedModalLinkProps = [
	'notitle', 'iframe',
	'width', 'height'
]

const bootstrapModals = () => {
	connectNodes('.modalLink_inline', openModalInline)
	connectNodes('.learning-sphere-picker-link', openModalLearningSphere)
}

const connectNodes = (selector, callback) => {
	const nodeList = document.querySelectorAll(selector)
	let element
	forEach(nodeList, link => {
		link.addEventListener('click', e => {
			e.preventDefault()
			element = e.target.closest(selector)
			callback(element)
		})
	})
}

const openModalLearningSphere = (link) => {
	const title = link.textContent
  const content = <LearningSphereList />
  const options = {
    type: 'learning-sphere-picker',
  }

	renderModal({
		title, content, options
	})
}

const openModalInline = (link) => {
	let title = link.textContent
	const contentElem = getElementByHashLink(link)

  if(!contentElem) return

	const contentHtml = contentElem.innerHTML

	if(!contentHtml) return

	const dataOptions = sanitizeElementData(
		link, {
			allowedKeys: allowedModalLinkProps,
			sanitizeKeyFunc: key => key.replace(/options/, '').toLowerCase()
		})

	if(contentElem.dataset && contentElem.dataset.title) {
		title = contentElem.dataset.title
	}

	const defaultOptions = {
		html: true,
	}

	const options = extend({}, defaultOptions, dataOptions)

  const content = <div dangerouslySetInnerHTML={ { __html: contentHtml } } />

	renderModal({
		title, content, options
	})
}

const renderModal = (data = {}) => {

	let modalContainer = document.getElementById('modal-container')

	if(!modalContainer) {
		modalContainer = document.createElement('div')
		modalContainer.id = 'modal-container'
		document.body.insertBefore(modalContainer, document.body.firstChild)
	}

	const props = {
		destroy: infernoRemoveRootNode(modalContainer),
		...data
	}

	render(
    <Modal {...props} >{data.content}</Modal>,
		modalContainer
	)
}

export default bootstrapModals
